// extracted by mini-css-extract-plugin
export var dateHolder = "projectCard-module--dateHolder--aLSx6";
export var divider = "projectCard-module--divider--YopWn";
export var mrTp20 = "projectCard-module--mrTp20--B2u2n";
export var postCardImg = "projectCard-module--postCardImg--57E0Y";
export var researchCard = "projectCard-module--researchCard--JQUb4";
export var rsBadgeBlue = "projectCard-module--rs-badge-blue--9f5jP";
export var rsBadgeContent = "projectCard-module--rs-badge-content--sgicd";
export var rsBadgeCyan = "projectCard-module--rs-badge-cyan--DKLZe";
export var rsBadgeGreen = "projectCard-module--rs-badge-green--HOxul";
export var rsBadgeIndependent = "projectCard-module--rs-badge-independent--wXoKJ";
export var rsBadgeOrange = "projectCard-module--rs-badge-orange--hjXky";
export var rsBadgeRed = "projectCard-module--rs-badge-red--yZtFa";
export var rsBadgeViolet = "projectCard-module--rs-badge-violet--TxPM8";
export var rsBadgeYellow = "projectCard-module--rs-badge-yellow--A44N8";
export var rsBtnBlue = "projectCard-module--rs-btn-blue--wT2ku";
export var rsBtnCyan = "projectCard-module--rs-btn-cyan--kKFnv";
export var rsBtnGreen = "projectCard-module--rs-btn-green--lePr2";
export var rsBtnOrange = "projectCard-module--rs-btn-orange--NN8WV";
export var rsBtnRed = "projectCard-module--rs-btn-red--KwKTF";
export var rsBtnViolet = "projectCard-module--rs-btn-violet--ahDZD";
export var rsBtnYellow = "projectCard-module--rs-btn-yellow--wg4Lk";
export var rsFlexBoxGridItem1 = "projectCard-module--rs-flex-box-grid-item-1--ZctAr";
export var rsFlexBoxGridItem10 = "projectCard-module--rs-flex-box-grid-item-10--c8QIz";
export var rsFlexBoxGridItem11 = "projectCard-module--rs-flex-box-grid-item-11--niUnn";
export var rsFlexBoxGridItem12 = "projectCard-module--rs-flex-box-grid-item-12---3uwM";
export var rsFlexBoxGridItem13 = "projectCard-module--rs-flex-box-grid-item-13--gkdza";
export var rsFlexBoxGridItem14 = "projectCard-module--rs-flex-box-grid-item-14--OIxvT";
export var rsFlexBoxGridItem15 = "projectCard-module--rs-flex-box-grid-item-15--OnODH";
export var rsFlexBoxGridItem16 = "projectCard-module--rs-flex-box-grid-item-16--u-51U";
export var rsFlexBoxGridItem17 = "projectCard-module--rs-flex-box-grid-item-17--r9899";
export var rsFlexBoxGridItem18 = "projectCard-module--rs-flex-box-grid-item-18--L75t-";
export var rsFlexBoxGridItem19 = "projectCard-module--rs-flex-box-grid-item-19--QTzDQ";
export var rsFlexBoxGridItem2 = "projectCard-module--rs-flex-box-grid-item-2--vHYCv";
export var rsFlexBoxGridItem20 = "projectCard-module--rs-flex-box-grid-item-20--vuNjs";
export var rsFlexBoxGridItem21 = "projectCard-module--rs-flex-box-grid-item-21--5ASA3";
export var rsFlexBoxGridItem22 = "projectCard-module--rs-flex-box-grid-item-22--BNhdj";
export var rsFlexBoxGridItem23 = "projectCard-module--rs-flex-box-grid-item-23--bkCXc";
export var rsFlexBoxGridItem24 = "projectCard-module--rs-flex-box-grid-item-24--ftO3n";
export var rsFlexBoxGridItem3 = "projectCard-module--rs-flex-box-grid-item-3--OX8Tp";
export var rsFlexBoxGridItem4 = "projectCard-module--rs-flex-box-grid-item-4--fxZHv";
export var rsFlexBoxGridItem5 = "projectCard-module--rs-flex-box-grid-item-5--ITfw7";
export var rsFlexBoxGridItem6 = "projectCard-module--rs-flex-box-grid-item-6--x7nu8";
export var rsFlexBoxGridItem7 = "projectCard-module--rs-flex-box-grid-item-7--0BEnu";
export var rsFlexBoxGridItem8 = "projectCard-module--rs-flex-box-grid-item-8--aYXV9";
export var rsFlexBoxGridItem9 = "projectCard-module--rs-flex-box-grid-item-9--427mj";
export var rsFlexBoxGridItemOrder1 = "projectCard-module--rs-flex-box-grid-item-order-1--UNI-d";
export var rsFlexBoxGridItemOrder10 = "projectCard-module--rs-flex-box-grid-item-order-10--yk9e5";
export var rsFlexBoxGridItemOrder11 = "projectCard-module--rs-flex-box-grid-item-order-11--3MxQv";
export var rsFlexBoxGridItemOrder12 = "projectCard-module--rs-flex-box-grid-item-order-12--+X20w";
export var rsFlexBoxGridItemOrder13 = "projectCard-module--rs-flex-box-grid-item-order-13--aXYS-";
export var rsFlexBoxGridItemOrder14 = "projectCard-module--rs-flex-box-grid-item-order-14--QDp1Y";
export var rsFlexBoxGridItemOrder15 = "projectCard-module--rs-flex-box-grid-item-order-15--yqJr-";
export var rsFlexBoxGridItemOrder16 = "projectCard-module--rs-flex-box-grid-item-order-16--o3uvZ";
export var rsFlexBoxGridItemOrder17 = "projectCard-module--rs-flex-box-grid-item-order-17--aA7IO";
export var rsFlexBoxGridItemOrder18 = "projectCard-module--rs-flex-box-grid-item-order-18--ZlMH9";
export var rsFlexBoxGridItemOrder19 = "projectCard-module--rs-flex-box-grid-item-order-19--oPg1c";
export var rsFlexBoxGridItemOrder2 = "projectCard-module--rs-flex-box-grid-item-order-2--hPhA5";
export var rsFlexBoxGridItemOrder20 = "projectCard-module--rs-flex-box-grid-item-order-20--pv1P9";
export var rsFlexBoxGridItemOrder21 = "projectCard-module--rs-flex-box-grid-item-order-21--O10YP";
export var rsFlexBoxGridItemOrder22 = "projectCard-module--rs-flex-box-grid-item-order-22--eqduo";
export var rsFlexBoxGridItemOrder23 = "projectCard-module--rs-flex-box-grid-item-order-23--PTWSU";
export var rsFlexBoxGridItemOrder24 = "projectCard-module--rs-flex-box-grid-item-order-24--vEg79";
export var rsFlexBoxGridItemOrder3 = "projectCard-module--rs-flex-box-grid-item-order-3--GGvYA";
export var rsFlexBoxGridItemOrder4 = "projectCard-module--rs-flex-box-grid-item-order-4--akxoL";
export var rsFlexBoxGridItemOrder5 = "projectCard-module--rs-flex-box-grid-item-order-5--bVs40";
export var rsFlexBoxGridItemOrder6 = "projectCard-module--rs-flex-box-grid-item-order-6--QIusa";
export var rsFlexBoxGridItemOrder7 = "projectCard-module--rs-flex-box-grid-item-order-7--0lyD4";
export var rsFlexBoxGridItemOrder8 = "projectCard-module--rs-flex-box-grid-item-order-8--N5qH5";
export var rsFlexBoxGridItemOrder9 = "projectCard-module--rs-flex-box-grid-item-order-9--JOcZy";
export var rsTagBlue = "projectCard-module--rs-tag-blue--T1-q2";
export var rsTagCyan = "projectCard-module--rs-tag-cyan--e2R85";
export var rsTagGreen = "projectCard-module--rs-tag-green--WFeni";
export var rsTagOrange = "projectCard-module--rs-tag-orange--EUgMG";
export var rsTagRed = "projectCard-module--rs-tag-red--dfchY";
export var rsTagViolet = "projectCard-module--rs-tag-violet--fsubE";
export var rsTagYellow = "projectCard-module--rs-tag-yellow--y0Ydp";
export var rsThemeDark = "projectCard-module--rs-theme-dark--gq1cO";
export var rsThemeHighContrast = "projectCard-module--rs-theme-high-contrast--rykmb";
export var title = "projectCard-module--title--seeDW";