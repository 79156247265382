// extracted by mini-css-extract-plugin
export var dateHolder = "conferenceCard-module--dateHolder--P-VIt";
export var divider = "conferenceCard-module--divider--dJHxU";
export var mrTp20 = "conferenceCard-module--mrTp20--puO-d";
export var postCardImg = "conferenceCard-module--postCardImg--KhJfM";
export var researchCard = "conferenceCard-module--researchCard--T69p+";
export var rsBadgeBlue = "conferenceCard-module--rs-badge-blue--6-fi-";
export var rsBadgeContent = "conferenceCard-module--rs-badge-content--+GQxg";
export var rsBadgeCyan = "conferenceCard-module--rs-badge-cyan--VmJZt";
export var rsBadgeGreen = "conferenceCard-module--rs-badge-green--7Ux04";
export var rsBadgeIndependent = "conferenceCard-module--rs-badge-independent--gOQBo";
export var rsBadgeOrange = "conferenceCard-module--rs-badge-orange--KNVoM";
export var rsBadgeRed = "conferenceCard-module--rs-badge-red--QsjZI";
export var rsBadgeViolet = "conferenceCard-module--rs-badge-violet--FupCt";
export var rsBadgeYellow = "conferenceCard-module--rs-badge-yellow--zHI7R";
export var rsBtnBlue = "conferenceCard-module--rs-btn-blue--VTPKY";
export var rsBtnCyan = "conferenceCard-module--rs-btn-cyan--FMah5";
export var rsBtnGreen = "conferenceCard-module--rs-btn-green--Dt2lA";
export var rsBtnOrange = "conferenceCard-module--rs-btn-orange--QQLgZ";
export var rsBtnRed = "conferenceCard-module--rs-btn-red--koMgi";
export var rsBtnViolet = "conferenceCard-module--rs-btn-violet--U05mn";
export var rsBtnYellow = "conferenceCard-module--rs-btn-yellow--a7Qps";
export var rsFlexBoxGridItem1 = "conferenceCard-module--rs-flex-box-grid-item-1--m-VYY";
export var rsFlexBoxGridItem10 = "conferenceCard-module--rs-flex-box-grid-item-10--zJbmc";
export var rsFlexBoxGridItem11 = "conferenceCard-module--rs-flex-box-grid-item-11--U0WpI";
export var rsFlexBoxGridItem12 = "conferenceCard-module--rs-flex-box-grid-item-12--ysiEM";
export var rsFlexBoxGridItem13 = "conferenceCard-module--rs-flex-box-grid-item-13--PRl3U";
export var rsFlexBoxGridItem14 = "conferenceCard-module--rs-flex-box-grid-item-14--rO6Jb";
export var rsFlexBoxGridItem15 = "conferenceCard-module--rs-flex-box-grid-item-15--+LSm6";
export var rsFlexBoxGridItem16 = "conferenceCard-module--rs-flex-box-grid-item-16--JH9GS";
export var rsFlexBoxGridItem17 = "conferenceCard-module--rs-flex-box-grid-item-17--gvJMg";
export var rsFlexBoxGridItem18 = "conferenceCard-module--rs-flex-box-grid-item-18--QOM9-";
export var rsFlexBoxGridItem19 = "conferenceCard-module--rs-flex-box-grid-item-19--QM4aB";
export var rsFlexBoxGridItem2 = "conferenceCard-module--rs-flex-box-grid-item-2--mmtBW";
export var rsFlexBoxGridItem20 = "conferenceCard-module--rs-flex-box-grid-item-20--28yye";
export var rsFlexBoxGridItem21 = "conferenceCard-module--rs-flex-box-grid-item-21--Qg2Z9";
export var rsFlexBoxGridItem22 = "conferenceCard-module--rs-flex-box-grid-item-22--sTnR3";
export var rsFlexBoxGridItem23 = "conferenceCard-module--rs-flex-box-grid-item-23--T3VVD";
export var rsFlexBoxGridItem24 = "conferenceCard-module--rs-flex-box-grid-item-24--8RbYr";
export var rsFlexBoxGridItem3 = "conferenceCard-module--rs-flex-box-grid-item-3--9wb1Z";
export var rsFlexBoxGridItem4 = "conferenceCard-module--rs-flex-box-grid-item-4--8gZSr";
export var rsFlexBoxGridItem5 = "conferenceCard-module--rs-flex-box-grid-item-5--fpKTX";
export var rsFlexBoxGridItem6 = "conferenceCard-module--rs-flex-box-grid-item-6--u2aZQ";
export var rsFlexBoxGridItem7 = "conferenceCard-module--rs-flex-box-grid-item-7--6c2f4";
export var rsFlexBoxGridItem8 = "conferenceCard-module--rs-flex-box-grid-item-8--ygLtL";
export var rsFlexBoxGridItem9 = "conferenceCard-module--rs-flex-box-grid-item-9--vh1MM";
export var rsFlexBoxGridItemOrder1 = "conferenceCard-module--rs-flex-box-grid-item-order-1--G6z34";
export var rsFlexBoxGridItemOrder10 = "conferenceCard-module--rs-flex-box-grid-item-order-10--Suuvu";
export var rsFlexBoxGridItemOrder11 = "conferenceCard-module--rs-flex-box-grid-item-order-11--EVGuU";
export var rsFlexBoxGridItemOrder12 = "conferenceCard-module--rs-flex-box-grid-item-order-12--a-iPU";
export var rsFlexBoxGridItemOrder13 = "conferenceCard-module--rs-flex-box-grid-item-order-13--3tTAp";
export var rsFlexBoxGridItemOrder14 = "conferenceCard-module--rs-flex-box-grid-item-order-14--BxyXd";
export var rsFlexBoxGridItemOrder15 = "conferenceCard-module--rs-flex-box-grid-item-order-15--rtsa5";
export var rsFlexBoxGridItemOrder16 = "conferenceCard-module--rs-flex-box-grid-item-order-16--XdNb7";
export var rsFlexBoxGridItemOrder17 = "conferenceCard-module--rs-flex-box-grid-item-order-17--Se+GU";
export var rsFlexBoxGridItemOrder18 = "conferenceCard-module--rs-flex-box-grid-item-order-18--dC0Ie";
export var rsFlexBoxGridItemOrder19 = "conferenceCard-module--rs-flex-box-grid-item-order-19--miRxY";
export var rsFlexBoxGridItemOrder2 = "conferenceCard-module--rs-flex-box-grid-item-order-2--yywYp";
export var rsFlexBoxGridItemOrder20 = "conferenceCard-module--rs-flex-box-grid-item-order-20--969Te";
export var rsFlexBoxGridItemOrder21 = "conferenceCard-module--rs-flex-box-grid-item-order-21--bmr-N";
export var rsFlexBoxGridItemOrder22 = "conferenceCard-module--rs-flex-box-grid-item-order-22--pjqY2";
export var rsFlexBoxGridItemOrder23 = "conferenceCard-module--rs-flex-box-grid-item-order-23--qt0zs";
export var rsFlexBoxGridItemOrder24 = "conferenceCard-module--rs-flex-box-grid-item-order-24--fRG-i";
export var rsFlexBoxGridItemOrder3 = "conferenceCard-module--rs-flex-box-grid-item-order-3--Myi8C";
export var rsFlexBoxGridItemOrder4 = "conferenceCard-module--rs-flex-box-grid-item-order-4--P1ovc";
export var rsFlexBoxGridItemOrder5 = "conferenceCard-module--rs-flex-box-grid-item-order-5--OsRyQ";
export var rsFlexBoxGridItemOrder6 = "conferenceCard-module--rs-flex-box-grid-item-order-6--YplAJ";
export var rsFlexBoxGridItemOrder7 = "conferenceCard-module--rs-flex-box-grid-item-order-7--7b-hu";
export var rsFlexBoxGridItemOrder8 = "conferenceCard-module--rs-flex-box-grid-item-order-8--H1PQB";
export var rsFlexBoxGridItemOrder9 = "conferenceCard-module--rs-flex-box-grid-item-order-9--25Q3W";
export var rsTagBlue = "conferenceCard-module--rs-tag-blue--R0AzF";
export var rsTagCyan = "conferenceCard-module--rs-tag-cyan--XXvmC";
export var rsTagGreen = "conferenceCard-module--rs-tag-green--C6lAB";
export var rsTagOrange = "conferenceCard-module--rs-tag-orange--52Pjd";
export var rsTagRed = "conferenceCard-module--rs-tag-red--RXYoB";
export var rsTagViolet = "conferenceCard-module--rs-tag-violet--9XoO-";
export var rsTagYellow = "conferenceCard-module--rs-tag-yellow--Ex2r8";
export var rsThemeDark = "conferenceCard-module--rs-theme-dark--UAWku";
export var rsThemeHighContrast = "conferenceCard-module--rs-theme-high-contrast--zE16L";
export var title = "conferenceCard-module--title--ZhVYk";